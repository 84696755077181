import { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { postData, postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faLocationDot, faFile, faClose, faTableList, faTimeline, faChevronLeft, faPaperclip, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faUser, faEnvelope, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';
import ImportFile from '../downloads/invoice_update_import.xlsx';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';


class InvoiceUpload extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],            
      selectedOrder : {},      
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);    
  }

  async getOrderInvoices(){
    try {
      const response = await fetch('api/order-invoices');
      const json = await response.json();
      this.setState({ data:json });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  
  add() {
    this.setState({
      id: "",
      order_id: "",
      show: true,
      show_content: "form"
    });
  } 

  delete(Ids) {        
    deleteItem(`/api/order-invoices?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getOrderInvoices();
    });
  }

  componentDidMount() {    
    this.getOrderInvoices();
  }  

  handleInputChange(event){
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }  

  handleSubmit(event) {
    event.preventDefault();
    var ORfileds = document.getElementById("order-fields");
    ORfileds.disabled=true;
    var formData = new FormData();
    var file = document.getElementById("order-sheet").files[0];
    if(file){
      formData.append('File', file);
      formData.append("order_id", this.state.order_id);
    }    

    postFile('/api/order-invoices', formData)
      .then((response) => {
        toast.success(response.message);
        ORfileds.disabled=false;        
        document.getElementById("order-form").reset();
        this.setState({show: false, show_content:""});        
        this.getOrderInvoices();
    }).catch(e=>{
        console.error(e);
        toast.error("Listitem matches with existing SARAL No!. Cannot add items to the list");
        this.setState({show: false, show_content:""});
    });
  }  

  render() {
    if(!this.state.isLoading) {          
      return(
        <>
          {!this.state.show?(
            <>
              <div className="d-flex">              
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Order Invoices
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>
              <OrderInvoicesList data={this.state.data} delete={this.delete} />
            </>
          ):(      
            <>
              <div className="card order-form p-3 bg-white shadow-sm rounded w-50 border-0">
                <h4 className="mb-3 fw-bold"> Order Invoices Upload </h4>
                <form id="order-form" onSubmit={this.handleSubmit}>
                  <fieldset id="order-fields" className="my-0 mx-auto">                                            
                    <div className="mb-3">
                      <p className="text-secondary fw-light">Upload your excel sheet here to generate the consumer list.</p>  
                      <label htmlFor="title">Order vs Invoice List File</label>
                      <input type="file" id="order-sheet" className="form-control" onChange={this.handleFileChange} required /> 
                    </div>
                    <div className="my-2">
                      <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                      <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                    </div>                                          
                  </fieldset>
                </form>
              </div>
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const OrderInvoicesList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    order: "",
    status: "",
    searchText: ""
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);  

  const handleFilterChange = (e)=> {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((prevValues)=> ({
      ...prevValues,
      [name] : value
    }));
    let filteredItems = [];

    if(name==="searchText"){
      filteredItems = props.data.filter(item => {        
        const primaryNoMatches   = item.primary_no && item.primary_no.toLowerCase().includes(value.toLowerCase());
        const inv1Matches   = item.invoice_1_no && item.invoice_1_no.toLowerCase().includes(value.toLowerCase());
        const inv2Matches   = item.invoice_2_no && item.invoice_2_no.toLowerCase().includes(value.toLowerCase());
        return primaryNoMatches || inv1Matches || inv2Matches;
      });
    }else{
      filteredItems = props.data.filter(item => item.order_id == (value?value:item.order_id));
    }

    setData(filteredItems);
  }

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filters.serachText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilters((prevValues)=> ({
          ...prevValues,
          "searchText" : ""
        }));
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Invoice_Report_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex align-items-center">
        <div className="input-group input-group-sm me-2">                  
          <input
            name="searchText"
            type="text" 
            className="form-control shadow-none w-25" 
            placeholder="Search by application no, invoice no ..." 
            aria-label="Search area"          
            value={filters.serachText} 
            onChange={handleFilterChange}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileLines} /> Report
          </button>
        </div>
      </div>
    );
  }, [filters.serachText, resetPaginationToggle]);

  const columns = [
      {
          name: 'Primary No.',
          selector: row => row.primary_no,
          sortable: true,
      },      
      {
          name: 'Invoice 1 No',
          selector: row => row.invoice_1_no,
          sortable: true,
      },
      {
          name: 'Invoice 2 No',
          selector: row => row.invoice_2_no,
          sortable: true,
      },
      {
          name: 'Invoice Date',
          selector: row => row.invoice_date,
          sortable: true,
      }
  ]; 

  const tableData = data.map((item, index)=>{
    return {
      id            : item.id,
      primary_no    : item.primary_no,
      invoice_1_no  : item.invoice_1_no,
      invoice_2_no  : item.invoice_2_no,
      invoice_date  : item.invoice_date?moment(item.invoice_date).format("lll"):""
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Order Invoices</h4>
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.primary_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    setData(props.data);
  },[props.data])

  
  return(
    <>      
      <div id="order-list" className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={tableData}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"        
            pointerOnHover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}              
          />
        </div>
      </div>
    </>
  );  
}


export { InvoiceUpload };
