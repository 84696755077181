import { Component, useEffect, useState, useCallback, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil, faPlus, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { Col, ListGroup, Row, Tab, Tabs, Card, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';


class InstallEngineers extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],         
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);    
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getInstallEngineers(){
    try {
      const response = await fetch(`/api/install-engineers?docRef=[${this.props.installer.id}]`);
      const json = await response.json();
      console.log("iEngineers: ", json);
      this.setState({ 
        data: json.engineers.length?json.engineers:[],
        role_id: json.role_id,
        organisation_id: this.props.installer.id,
        installer_company_name: this.props.installer.org_name
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      username: "",      
      email: "",
      password: "",
      phone: "",            
      is_active: 1,
      show: true,
      show_content: "form"
    });
  }

  edit(Id) {    
    const engineers = this.state.data;
    const selectedEngineer = engineers.filter(e=> e.id === Id)[0];
    this.setState(selectedEngineer);
    this.setState({ show: true });
  }

  delete(Ids) {        
    deleteItem(`/api/install-engineers?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getInstallEngineers();
    });
  }

  componentDidMount() {
    this.getInstallEngineers();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/install-engineer/'+data.id, data)
        .then((response) => {
          toast.success(response.message);
          this.getInstallEngineers();          
      });
    }else{
      postData('/api/install-engineer', data)
        .then((response) => {
          toast.success(response.message);          
          this.getInstallEngineers();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;    

      return(                         
          <Container fluid className="h-100">            
            {this.state.show?(
                <>
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="install-engineer-form p-4 bg-white shadow-sm rounded border-0 w-50">
                      <h5 className="mb-3 fw-semibold text-secondary"> {this.state.id?"Edit Install Engineer":"New Install Engineer"} </h5>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="install-engineer-from" className="my-0 mx-auto">
                          <div className="row">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="username">Engineer Name</label>
                              <input type="text" name="username" className="form-control shadow-none" maxLength="100" value={this.state.username} onChange={this.handleInputChange} required />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="organisation_id">Installer Company</label>
                              <input name="organisation_id" className="form-control shadow-none bg-light" value={this.state.installer_company_name} readOnly />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="email">Email</label>
                              <input type="email" name="email" className="form-control shadow-none" maxLength="50" value={this.state.email} onChange={this.handleInputChange} required />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="password">Password</label>
                              <input type="text" name="password" className="form-control shadow-none" maxLength="100" value={this.state.password} onChange={this.handleInputChange} required />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="phone">Phone</label>
                              <input type="text" name="phone" className="form-control shadow-none" maxLength="20" value={this.state.phone} onChange={this.handleInputChange} />
                            </div>                                                                         
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="title">Status</label>
                              <select name="is_active" className="form-select shadow-none" value={this.state.is_active} onChange={this.handleInputChange} required>
                                <option value="0"> Inactive </option>
                                <option value="1"> Active </option>
                              </select>
                            </div>
                          </div>                        
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                   </div> 
                </>
              ):(
                <>
                  <button className="btn btn-sm btn-primary fw-bold mx-2 mb-2" onClick={this.add}>
                    <FontAwesomeIcon icon={faPlus} /> Add Install Engineer
                  </button>
                  <button className="btn btn-sm btn-secondary fw-bold mx-2 mb-2" onClick={this.props.close}>
                    <FontAwesomeIcon icon={faClose} /> Close
                  </button> 
                  <EngineerList data={data} installer={this.props.installer} edit={this.edit} delete={this.delete} />                                                 
                </>
            )}            
          </Container>                          
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const EngineerList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const nameMatches = item.username && item.username.toLowerCase().includes(filterText.toLowerCase());   
    return nameMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_${props.installer.org_name}_Install_Engineers_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by engineer's name" 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]); 

  const columns = [      
      {
          name: 'Name',
          selector: row => row.engineer_name,
          sortable: true,
      },
      {
          name: 'Email',
          selector: row => row.email,
      },
      {
          name: 'Phone',
          selector: row => row.phone,
      },          
      {
          name: 'Status',
          selector: row => row.status,
      },
      {
          name: 'Action',
          selector: row => row.edit,
      }
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,      
      engineer_name: item.username,
      email: item.email,     
      phone: item.phone,
      status: <span className={`${item.is_active?"fw-semibold":"fw-bold text-danger"}`}>{item.is_active?"Active":"Inactive"}</span>,
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" onClick={()=> props.edit(item.id)} />
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0 text-secondary fw-semibold">Install Engineers - ( {props.installer.org_name} )</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.engineer_name)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
             //remove this if you dont need row hover
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}                          
          />
        </div>
      </div>
    </>
  );
}

const ViewEngineer = (props)=> {
  return (
    <Card>
      <Card.Header className="text-secondary fw-semibold fs-5">Engineer details</Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <p className="mb-1">Engineer Name</p>
            <h6>{props.data.engineer_name}</h6>
          </div>
          <div className="col-md-12 mb-3">
            <p className="mb-1">Installer</p>
            <h6>{props.data.installer_company_name}</h6>
          </div>
          <div className="col-md-12 mb-3">
            <p className="mb-1">Email</p>
            <h6>{props.data.email}</h6>
          </div>
          <div className="col-md-12 mb-3">
            <p className="mb-1">Phone</p>
            <h6>{props.data.phone}</h6>
          </div>        
          <div className="col-md-12 mb-3">
            <p className="mb-1">Status</p>
            <h6 className={props.data.is_active?"text-success":"text-danger"}>{props.data.is_active?"Active":"Inactive"}</h6>
          </div>        
          <div>
            <button className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
          </div>
        </div>
      </Card.Body>
    </Card>    
  );
}

export { InstallEngineers };
