import { Component, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { postData, postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { OffCanvas } from '../partials/layouts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faLocationDot, faFile, faClose, faTableList, faTimeline, faChevronLeft, faPaperclip, faFileLines, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faUser, faEnvelope, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Table, Button, Col, Row } from 'react-bootstrap';
import ImportFile from '../downloads/consumers_import.xlsx';
import { toast } from 'react-toastify';

const XLSX = require("xlsx");
const moment = require('moment');


class ConsumerList extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],      
      projects: [],
      selectedOrder : {},      
      isLoading : true,
      show : false,
      show_content : "",
      project_filter : "",
      status_filter: ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStateUpdate = this.handleStateUpdate.bind(this);
  }

  async getOrders(){
    try {
      const response = await fetch('api/orders');
      const json = await response.json();
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getProjects(){
    try {
      const response = await fetch('api/projects');
      const json = await response.json();
      this.setState({ projects:json });
    } catch (error) {
      console.error(error);
    }
  }  

  add() {
    this.setState({
      id: "",
      project_id: "",
      show: true,
      show_content: "form"
    });
  } 

  edit(e) {
    const id = parseInt(e.target.id);
    const orders = this.state.data;
    const selectedOrder = orders.filter(e=> e.order_id === id)[0];
    this.setState(selectedOrder);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  view(row, event) {
    const selectedOrder = this.state.data.filter(e=> e.id === row.id)[0];
    this.setState({show: true, show_content: "", selectedOrder: selectedOrder});
  }

  delete(Ids) {        
    deleteItem(`/api/orders?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getInstallEngineers();
    });
  }

  componentDidMount() {
    this.getProjects();
    this.getOrders();
  }

  handleStateUpdate = (updatedObj) => {
    var currentDate = new Date();
    this.setState((prevState) => ({
      selectedOrder: {
        ...prevState.selectedOrder,
        [updatedObj.key]: updatedObj.value,
        [updatedObj.fields.completed_date]: currentDate,
        [updatedObj.fields.completed_by]: sessionStorage.getItem("userName")
      },
      data: prevState.data.map((item) =>
        item.id === updatedObj.order_id
          ? { 
              ...item, 
              [updatedObj.key]: updatedObj.value,
              [updatedObj.fields.completed_date]: currentDate,
              [updatedObj.fields.completed_by]: sessionStorage.getItem("userName")
            }
          : item
      ),
    }));
  };  

  handleInputChange(event){
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }  

  handleSubmit(event) {
    event.preventDefault();
    var ORfileds = document.getElementById("order-fields");
    ORfileds.disabled=true;
    var formData = new FormData();
    var file = document.getElementById("order-sheet").files[0];
    if(file){
      formData.append('File', file);
      formData.append("project_id", this.state.project_id);
    }    

    postFile('/api/order', formData)
      .then((response) => {
        toast.success(response.message);
        ORfileds.disabled=false;        
        document.getElementById("order-form").reset();
        this.setState({show: false, show_content:""});        
        this.getOrders();
    }).catch(e=>{
        console.error(e);
        toast.error("Listitem matches with existing SARAL No!. Cannot add items to the list");
        this.setState({show: false, show_content:""});
    });
  }  

  render() {
    if(!this.state.isLoading) {          
      return(
        <>
          {!this.state.show?(
            <>
              <div className="d-flex">              
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Consumer List
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>
              <OrderList data={this.state.data} projects={this.state.projects} edit={this.edit} delete={this.delete} view={this.view} /> 
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="card order-form p-3 bg-white shadow-sm rounded w-50 border-0">
                    <h4 className="mb-3 fw-bold"> New Consumer List </h4>
                    <form id="order-form" onSubmit={this.handleSubmit}>
                      <fieldset id="order-fields" className="my-0 mx-auto"> 
                        <div className="mb-3">                          
                          <label htmlFor="title">Project</label>
                          <select name="project_id" className="form-select" value={this.state.project_id} onChange={this.handleInputChange}> 
                            <option value=""> -- Select Project --</option>
                            {
                              this.state.projects.map(p=>
                                <option key={"PJ"+p.id} value={p.id}> {p.project_code} - {p.project_name} </option>
                              )
                            }
                          </select>
                        </div>
                        {
                          this.state.project_id &&
                          <> 
                            <div className="mb-3">
                              <p className="text-secondary fw-light">Upload your excel sheet here to generate the consumer list.</p>  
                              <label htmlFor="title">Consumer List File</label>
                              <input type="file" id="order-sheet" className="form-control" onChange={this.handleFileChange} required /> 
                            </div>
                            <div className="my-2">
                              <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                              <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                            </div>
                          </>
                        }                        
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <OrderDetails data={this.state.selectedOrder} onStateUpdate={this.handleStateUpdate} close={()=> this.setState({show: false})} />
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const OrderList = (props)=> {
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    project: "",
    status: "",
    searchText: ""
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);  

  const handleFilterChange = (e)=> {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((prevValues)=> ({
      ...prevValues,
      [name] : value
    }));
    let filteredItems = [];

    if(name==="searchText"){
      filteredItems = props.data.filter(item => {
        const farmerMatches  = item.farmer_name && item.farmer_name.toLowerCase().includes(value.toLowerCase());
        const primaryNoMatches   = item.primary_no && item.primary_no.toLowerCase().includes(value.toLowerCase());
        return farmerMatches || primaryNoMatches;
      });
    }else{
      filteredItems = props.data.filter(item => item[name] == (value?value:item[name]));
    }

    setData(filteredItems);
  }

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filters.serachText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilters((prevValues)=> ({
          ...prevValues,
          "searchText" : ""
        }));
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Consumer_Report_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex align-items-center">
        <div className="input-group input-group-sm me-2">
          <select name="project_code" className="form-select shadow-none" value={filters.project_code} onChange={handleFilterChange} style={{maxWidth: '220px'}} >
            <option value="">-- Project filter --</option> 
            {
              props.projects.map(p=>
                <option key={"P"+p.id} value={p.project_code}>{p.project_name}</option> 
              )
            }
          </select>          
          <select name="application_status" className="form-select shadow-none" value={filters.application_status} onChange={handleFilterChange} style={{maxWidth: '220px'}}>
            <option value="">-- Status filter --</option>
            {
              orderStatuses.map((o,i)=>
                <option key={o.status+i} value={o.activity}>{o.activity}</option> 
              )
            }
          </select>
          <input
            name="searchText"
            type="text" 
            className="form-control shadow-none" 
            placeholder="Search by farmer name, saral no ..." 
            aria-label="Search area"          
            value={filters.serachText} 
            onChange={handleFilterChange}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileLines} /> Report
          </button>
        </div>
      </div>
    );
  }, [filters, resetPaginationToggle, orderStatuses,]);

  const columns = [
      {
          name: 'Order Primary No.',
          selector: row => row.primary_no,
          sortable: true,         
      },
      {
          name: 'Order Date',
          selector: row => row.order_date,
          sortable: true,          
      },
      {
          name: 'Project Code',
          selector: row => row.project_code,
          sortable: true,          
      },
      {
          name: 'Farmer Name',
          selector: row => row.farmer_name,
          sortable: true,
      },
      {
          name: 'Status',
          selector: row => row.application_status,
          sortable: true,
      },
      {
          name: 'Motor Type',
          selector: row => row.pump_type,
          sortable: true,
      },
      {
          name: 'Pump Sub Type',
          selector: row => row.pump_sub_type,
          sortable: true,
      },
      {
          name: 'Pump Capacity',
          selector: row => row.pump_capacity,
      }
  ]; 

  const tableData = data.map((item, index)=>{
    return {
      id            : item.id,
      primary_no    : item.primary_no,
      order_date    : moment(item.order_date).format("DD/MM/YYYY HH:MM"),
      project_code  : item.project_code,
      farmer_name   : item.farmer_name,
      application_status : item.application_status,
      pump_type: item.pump_type,
      pump_sub_type : item.pump_sub_type,
      pump_capacity : item.pump_capacity,
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Consumer List</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.primary_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  const getOrderStatuses = async()=> {
    try{
      const response = await fetch("/api/order-activities");
      const json = await response.json();
      setOrderStatuses(json.length?json:[]);
    }catch(e){
      toast.error(e)
    }
  }

  useEffect(()=>{
    setData(props.data);
    getOrderStatuses();
  },[props.data])

  
  return(
    <>      
      <div id="order-list" className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={tableData}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="440px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"             
            pointerOnHover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}  
            onRowClicked={props.view}
          />
        </div>
      </div>
    </>
  );  
}

function OrderDetails(props) {  
  const [orderDetails, setOrderDetails] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [showTimeline, setShowTimeline] = useState(false);
  const [orderActivities, setOrderActivities] = useState([]);  
  const [isLoading, setLoading] = useState(true);

  const getOrderDetails = async()=> {
    try{
      const response = await fetch(`/api/order/${props.data.id}`);
      const json = await response.json();
      console.log(json);
      setOrderDetails(json.order.primary_no?json.order:{});
      setOrderItems(json.orderItems.length?json.orderItems:[]);
    }catch(e){
      toast.error(e);
    }finally{
      setLoading(false);
    }
  }

  const getOrderActivities = async()=> {
    try{
      const response = await fetch("/api/order-activities");
      const json = await response.json();
      setOrderActivities(json.length?json:[]);
    }catch(e){
      toast.error(e)
    }
  }

  async function generateInvoice() {        
    try {
      const response = await fetch(`api/invoice-report/${props.data.id}`);
      const htmlData = await response.text();
      var myWindow = window.open("", "Invoice");
      myWindow.document.write(htmlData);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }   
  }

  async function generateJCR() {        
    try {
      const response = await fetch(`api/jcr-report/${props.data.id}`);
      const htmlData = await response.text();
      var myWindow = window.open("", "Invoice");
      myWindow.document.write(htmlData);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }   
  }

  useEffect(()=>{
    getOrderDetails();
  },[props])

  return (
    !isLoading?(
      <div className="container-fluid bg-white p-3">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <button className="btn border-primary rounded-0 me-2" onClick={()=> generateInvoice()}>
              <FontAwesomeIcon icon={faFileInvoice} color="blue" />
              <span className="text-primary fw-semibold"> Generate Invoice </span>
            </button>
            <button className="btn border-primary rounded-0 mx-2" onClick={()=> generateJCR()}>
              <FontAwesomeIcon icon={faFile} color="blue" />
              <span className="text-primary fw-semibold"> JCR Report </span>
            </button>
          </div>
          <button className="btn btn-outline-secondary rounded-0" onClick={props.close}>
            <FontAwesomeIcon icon={faClose} /> Close
          </button>
        </div>
        <Row xs={12}>
          <Col xs={12} sm={6} lg={6} className="mb-3">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="2">Order & Project Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Order Primary No.</td>
                  <td className="fw-semibold">{orderDetails.primary_no}</td>
                </tr>
                <tr>
                  <td>Order Date</td>
                  <td className="fw-semibold">{orderDetails.order_date?moment(orderDetails.order_date).format("LLL"):""}</td>
                </tr>
                <tr>
                  <td>Work Order No.</td>
                  <td className="fw-semibold">{orderDetails.work_order_no}</td>
                </tr>
                <tr>
                  <td>Application No.</td>
                  <td className="fw-semibold">{orderDetails.application_no}</td>
                </tr>
                <tr>
                  <td>Project Code</td>
                  <td className="fw-semibold">{orderDetails.project_code}</td>
                </tr>
                <tr>
                  <td>Project Name</td>
                  <td className="fw-semibold">{orderDetails.project_name}</td>
                </tr>
                <tr>
                  <td>Days to complete order</td>
                  <td className="fw-semibold">{orderDetails.ottc}</td>
                </tr>
                <tr>
                  <td>Days to complete installation</td>
                  <td className="fw-semibold">{orderDetails.ittc}</td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td className="fw-semibold text-success">{orderDetails.application_status}</td>
                </tr>
                <tr>
                  <td>Required Pump Type</td>
                  <td className="fw-semibold">{orderDetails.pump_type}</td>
                </tr>
                <tr>
                  <td>Required Pump Subtype</td>
                  <td className="fw-semibold">{orderDetails.pump_sub_type}</td>
                </tr>
                <tr>
                  <td>Required Pump Capacity</td>
                  <td className="fw-semibold">{orderDetails.pump_capacity}</td>
                </tr>
                <tr>
                  <td>Required Pump Head Length</td>
                  <td className="fw-semibold">{orderDetails.pump_head_mtrs}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={6} lg={6} className="mb-3">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="2">Consumer / Farmer Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SARAL No.</td>
                  <td className="fw-semibold">{orderDetails.bid_saral_no}</td>
                </tr>
                <tr>
                  <td>Farmer Name</td>
                  <td className="fw-semibold">{orderDetails.farmer_name}</td>
                </tr>
                <tr>
                  <td>Village</td>
                  <td className="fw-semibold">{orderDetails.village}</td>                       
                </tr>
                <tr>
                  <td>Taluka</td>
                  <td className="fw-semibold">{orderDetails.taluka}</td>
                </tr>
                <tr>
                  <td>District</td>
                  <td className="fw-semibold">{orderDetails.district}</td>
                </tr>
                <tr>
                  <td>State</td>
                  <td className="fw-semibold">{orderDetails.state}</td>
                </tr>
                <tr>
                  <td>Mobile Number</td>
                  <td className="fw-semibold">{orderDetails.mobile_number}</td>
                </tr>              
                <tr>
                  <td>Latitude</td>
                  <td className="fw-semibold">{orderDetails.latitude}</td>    
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td className="fw-semibold">{orderDetails.longitude}</td>
                </tr>
                <tr>
                  <td>Empanelled Agency</td>
                  <td className="fw-semibold">{orderDetails.empanelled_agency}</td>
                </tr>
              </tbody>
            </Table>                       
          </Col>
          <Col xs={12} sm={6} lg={6} className="mb-3">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="2">Installer Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Installer Code</td>
                  <td className="fw-semibold">{orderDetails.installer_code}</td>
                </tr>
                <tr>
                  <td>Company Name</td>
                  <td className="fw-semibold">{orderDetails.installer_company}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td className="fw-semibold">{orderDetails.installer_address}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td className="fw-semibold">{orderDetails.installer_email}</td>
                </tr>                
                <tr>
                  <td>Phone</td>
                  <td className="fw-semibold">{orderDetails.installer_phone}</td>
                </tr>              
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={6} lg={6} className="mb-3">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="2">Service Center Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Service Center Code</td>
                  <td className="fw-semibold">{orderDetails.service_center_code}</td>
                </tr>
                <tr>
                  <td>Company Name</td>
                  <td className="fw-semibold">{orderDetails.service_center_name}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td className="fw-semibold">{orderDetails.service_center_address}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td className="fw-semibold">{orderDetails.service_center_email}</td>
                </tr>                
                <tr>
                  <td>Phone</td>
                  <td className="fw-semibold">{orderDetails.service_center_phone}</td>
                </tr>              
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="2">Order Invoices</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Invoice 1</td>
                  <td className="fw-semibold">{orderDetails.invoice_1_no}</td>
                </tr>
                <tr>
                  <td>Invoice 2</td>
                  <td className="fw-semibold">{orderDetails.invoice_2_no}</td>
                </tr>
                <tr>
                  <td>Invoice Date</td>
                  <td className="fw-semibold">{orderDetails.invoice_date?moment(orderDetails.invoice_date).format("DD/MM/YY"):""}</td>
                </tr>                             
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={6} lg={8}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="3">Products Issued</th>
                </tr>
                <tr>
                  <th>Invoice No</th>
                  <th>Product Name</th>
                  <th className="text-end">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((row,index)=>
                  <tr key={"OP"+row.txn_no+index}>
                    <td>{row.txn_no}</td>
                    <td>{row.product_name}</td>
                    <td className="text-end">{row.units}</td>
                  </tr>                                                              
                )}
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={12} lg={12} className="mb-3">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="4">Order Assets</th>
                </tr>
                <tr>
                  <th>Motor SR No.</th>
                  <th>Controller SR No.</th>
                  <th>Pump Head SR No.</th>
                  <th>PV SR No.</th>
                </tr>
              </thead>
              <tbody>                
                <tr>
                  <td>{orderDetails.motor_sr_no}</td>
                  <td>{orderDetails.controller_sr_no}</td>
                  <td>
                    {orderDetails.pump_head_sr_no.map((row,index)=>
                      <Fragment key={row}>
                        <span>{row}</span><br/>
                      </Fragment>
                    )}
                  </td>
                  <td>{orderDetails.pv_sr_no}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={12} lg={12}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th colspan="4">Order Attachments</th>
                </tr>
                <tr>
                  <th>After Installation</th>
                  <th>Full System</th>
                  <th>Controller</th>
                  <th>Water Output</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href={orderDetails.after_foundation_photo_path?orderDetails.after_foundation_photo_path:"#"} target="_blank">
                      <img className="img-fluid" src={orderDetails.after_foundation_photo_path} alt="After Foundation Image" /> </a>
                  </td>
                  <td>
                    <a href={orderDetails.full_system_photo_path?orderDetails.full_system_photo_path:"#"} target="_blank">
                      <img className="img-fluid" src={orderDetails.full_system_photo_path} alt="Full System Image" /> </a>
                  </td>
                  <td>
                    <a href={orderDetails.controller_photo_path?orderDetails.controller_photo_path:"#"} target="_blank">
                      <img className="img-fluid" src={orderDetails.controller_photo_path} alt="Controller Image" /> </a>
                  </td>
                  <td>
                    <a href={orderDetails.water_output_photo_path?orderDetails.water_output_photo_path:"#"} target="_blank">
                      <img className="img-fluid" src={orderDetails.water_output_photo_path} alt="Water Output Image" /> </a>
                  </td>
                </tr>
                <tr>
                  <td>{orderDetails.after_foundation_photo_date}</td>
                  <td>{orderDetails.full_system_photo_date}</td>
                  <td>{orderDetails.controller_photo_date}</td>
                  <td>{orderDetails.water_output_photo_date}</td>
                </tr>
                <tr>
                  <td>{orderDetails.after_foundation_photo_updated_by}</td>
                  <td>{orderDetails.full_system_photo_updated_by}</td>
                  <td>{orderDetails.controller_photo_updated_by}</td>
                  <td>{orderDetails.water_output_photo_updated_by}</td>
                </tr>                             
              </tbody>
            </Table>
          </Col>
        </Row>        
      </div>
    ):(
      <> Loading ...</>
    )
  );
}


export { ConsumerList };
