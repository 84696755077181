import { Component, useState, useCallback, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil, faPlus, faClose, faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';


class Stocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],      
      warehouses: [],
      show: false,
      isLoading: true,
      show_content: "",
      warehouse: "",
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      txnType: ""
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.getStocks = this.getStocks.bind(this);
  }

  handleFilterChange(e){
    var name = e.target.name;
    var value = e.target.value;
    var type = e.target.type;
    if(type==="date") value = moment(value).format("YYYY-MM-DD");
    this.setState({ [name]: value });    
  }

  getStocks() {
    if (this.state.fromDate && this.state.toDate) {
      //fetch the stock from the server using the this.state object. pass the object as query params
      fetch(`/api/stocks?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&txn_filter=${this.state.txnType}&warehouse_filter=${this.state.warehouse}`)
        .then((response) => response.json())
        .then((result) => {
          if(result.length){
            result = result.map((item, index) => {
              item.ob = item.ob != null ? item.ob : 0;
              item.issues = item.issues != null ? item.issues : 0;
              item.receipts = item.receipts != null ? item.receipts : 0;
              item.cb = item.ob + item.receipts - item.issues;
              return item;
            });
            this.setState({data: result});
          }                   
        });
    }
  }

  async getWarehouses() {
    try {
      const response = await fetch(`/api/warehouses`);
      const json = await response.json();
      this.setState({ warehouses: json.length ? json : [] });
    } catch (error) {
      console.log(error);
    }finally{
      this.setState({isLoading: false})
    }
  }

  componentDidMount(){
    this.getWarehouses();    
  }

  render() {  
    return (
      <>
        {!this.state.isLoading?(
          <StockList 
            data={this.state.data}
            warehouses={this.state.warehouses} 
            getStocks={this.getStocks}
            handleFilterChange={this.handleFilterChange}
            filter={{
              'warehouse': this.state.warehouse,
              'fromDate' : this.state.fromDate, 
              'toDate'   : this.state.toDate,
              'txnType'  : this.state.txnType
            }}
          />        
        ):(
          <Spinner variant="primary" size="sm" />
        )}        
      </>
    );
  }
}

const StockList = (props) => {  
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const actionComponentMemo = useMemo(() => {    

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(props.data);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Stocks_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }    

    return (
      <div className="d-flex justify-content-end align-items-center">        
        <div className="input-group input-group-sm mx-2">
          <input name="fromDate" type="date" className="form-control shadow-none" onChange={props.handleFilterChange} value={props.filter.fromDate} />
          <input name="toDate" type="date" className="form-control shadow-none" onChange={props.handleFilterChange} value={props.filter.toDate} />
          <select
            name="warehouse"
            className="form-select shadow-none"
            value={props.filter.warehouse}
            onChange={props.handleFilterChange}
          >
            <option value=""> -- All Warehouses -- </option>
            {props.warehouses.map(w =>
              <option key={w.id} value={w.id}>{w.org_name}</option>
            )}
          </select>
          <select
            name="txnType" 
            className="form-select shadow-none" 
            value={props.filter.txnType} 
            onChange={props.handleFilterChange}
          >
            <option value="">-- All Transactions --</option>
            <option value="issue">Issues</option>
            <option value="receipt">Receipts</option>
          </select>
          <button className="btn btn-light border" type="button" onClick={props.getStocks}>
            <FontAwesomeIcon icon={faSearch} />
          </button>   
        </div> 
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [props.filter.txnType, props.filter.fromDate, props.toDate, props.filter.warehouse, resetPaginationToggle]);

  const columns = [
    {
      name: 'Product Name',
      selector: row => row.product_name,
      sortable: true,
    },
    {
      name: 'Opening Balance',
      selector: row => row.ob,
      sortable: true,
    },
    {
      name: 'Inwards',
      selector: row => row.receipts,
    },
    {
      name: 'Outwards',
      selector: row => row.issues,
    },
    {
      name: 'Closing Balance',
      selector: row => row.cb,
    }
  ];

  const THeader = () => {
    return (
      <>
        <h4 className="mb-0 text-secondary"> Stocks</h4>
      </>
    );
  }

  return (
    <>
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}
            columns={columns}
            data={props.data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll            
            //remove this if you dont need row hover
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
          />
        </div>
      </div>
    </>
  );
}


export { Stocks };
