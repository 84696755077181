import { Component, useState, useCallback, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil, faPlus, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ProductCategories } from '../utils/faker';


class Products extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],         
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getProducts(){
    try {
      const response = await fetch('/api/products');
      const json = await response.json();
      this.setState({ data: json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({
      id: "",
      product_name: "",
      hsn_code: "",
      category: "",
      uom: "",
      description: "",
      model: "",
      make: "",
      show: true,
      show_content: "form"
    });
  }

  view(Id) {    
    const products = this.state.data;
    const selectedProduct = products.filter(e=> e.id === Id)[0];
    this.setState(selectedProduct);
    this.setState({ show: true, show_content: "view", filename: "" });
  }

  edit(Id) {    
    const products = this.state.data;
    const selectedProduct = products.filter(e=> e.id === Id)[0];
    this.setState(selectedProduct);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  delete(Ids) {        
    deleteItem(`/api/products?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);      
      this.getProducts();
    });
  }

  componentDidMount() {
    this.getProducts();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/product/'+data.id, data)
        .then((response) => {
          toast.success(response.message);          
          this.getProducts();
      });
    }else{
      postData('/api/product', data)
        .then((response) => {
          toast.success(response.message);          
          this.getProducts();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;      

      return(
        <>
          {!this.state.show?(
            <>     
              <button className="btn btn-sm btn-primary fw-bold mx-2" onClick={this.add}>
                <FontAwesomeIcon icon={faPlus} /> Add Product
              </button>         
              <ProductList data={data} view={this.view} edit={this.edit} delete={this.delete} />
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="product-form p-3 bg-white shadow-sm rounded border-0 w-50">
                    <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Product":"New Product"} </h4>
                    <form onSubmit={this.handleSubmit}>
                      <fieldset id="product-from" className="my-0 mx-auto overflow-y-scroll px-2" style={{height: '77vh'}}>
                        <div className="row g-0">
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="product_name">Product Name</label>
                            <input type="text" name="product_name" className="form-control shadow-none" maxLength="100" value={this.state.product_name} onChange={this.handleInputChange} required />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="hsn_code">Code</label>
                            <input type="text" name="hsn_code" className="form-control shadow-none" maxLength="100" value={this.state.hsn_code} onChange={this.handleInputChange} required />
                          </div>                          
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="category">Category</label>
                            <select name="category" className="form-select shadow-none" value={this.state.category} onChange={this.handleInputChange} required>
                              <option value="">-- Select Category --</option>
                              {ProductCategories.map(cat=> 
                                <option value={cat.id}>{cat.name}</option>
                              )}
                            </select>
                          </div>                          
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="uom">Unit of Measurement (UOM)</label>
                            <select name="uom" className="form-select shadow-none" value={this.state.uom} onChange={this.handleInputChange} required>
                              <option value="">-- Select UOM --</option>
                              <option value="NOS"> NOS </option>
                              <option value="MTS"> MTS </option>
                              <option value="SET"> SET </option>
                            </select>
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="description">Description</label>
                            <textarea name="description" className="form-control shadow-none" rows={4} value={this.state.description} onChange={this.handleInputChange} />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="model">Model</label>
                            <input type="text" name="model" className="form-control shadow-none" maxLength="50" value={this.state.model} onChange={this.handleInputChange} />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="make">Make</label>
                            <input type="text" name="make" className="form-control shadow-none" maxLength="50" value={this.state.make} onChange={this.handleInputChange} />
                          </div>  
                        </div>                                        
                        <div className="pb-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <ViewProduct data={this.state} close={()=> this.setState({show: false})} />
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const ProductList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const nameMatches = item.product_name && item.product_name.toLowerCase().includes(filterText.toLowerCase());
    const codeMatches = item.hsn_code && item.hsn_code.toLowerCase().includes(filterText.toLowerCase());    
    const modelMatches= item.model && item.model.toLowerCase().includes(filterText.toLowerCase());
    const makeMatches = item.make && item.make.toLowerCase().includes(filterText.toLowerCase());

    return nameMatches || codeMatches || modelMatches || makeMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Products_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none w-25" 
           placeholder="Search by name, code, model, make ..." 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Products</h4>       
      </>
    );
  }

  const columns = [
      {
          name: 'Product Name',
          selector: row => row.product_name,
          sortable: true,
      }, 
      {
          name: 'Code',
          selector: row => row.hsn_code,
          sortable: true,
      },
      {
          name: 'Category',
          selector: row => row.category,
          sortable: true,
      }, 
      {
          name: 'UoM',
          selector: row => row.uom,
          sortable: true,
      },
      {
          name: 'Model',
          selector: row => row.model,
          sortable: true,
      },
      {
          name: 'Make',
          selector: row => row.make,
          sortable: true,
      },
      {
          name: 'Action',
          selector: row => row.edit,
      }
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,
      hsn_code: item.hsn_code,
      product_name: item.product_name,
      category: item.category,
      uom: item.uom,
      model: item.model,
      make: item.make,
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" onClick={()=> props.edit(item.id)} />
    }
  });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.product_name)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
             //remove this if you dont need row hover
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}                 
          />
        </div>
      </div>
    </>
  );
}

const ViewProduct = (props)=> {
  return (
    <div className="p-3 bg-light shadow-sm rounded">
      <h4 className="mb-3 fw-bold"> {props.data.title} </h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <p className="mb-1">Product Name</p>
          <h6>{props.data.product_name}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Vendor</p>
          <h6>{props.data.vendor_name}</h6>
        </div>               
        <div>
          <button className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
        </div>
      </div>
    </div>
  );
}

export { Products };
