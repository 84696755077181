import { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { postData, postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { OffCanvas } from '../partials/layouts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faLocationDot, faFile, faClose, faTableList, faTimeline, faChevronLeft, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faUser, faEnvelope, faFileExcel, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import ImportFile from '../downloads/motor_serial_import.xlsx';
import { toast } from 'react-toastify';
const XLSX = require("xlsx");
const moment = require('moment');


class Motors extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      selectedMotor : {},      
      isLoading : true,
      show : false,
      show_content : "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getMotors(){
    try {
      const response = await fetch('/api/motors');
      const json = await response.json();
      console.log(json);
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }  

  add() {
    this.setState({
      id: "",      
      show: true,
      show_content: "upload"
    });
  } 

  edit(Id) {    
    const products = this.state.data;
    const selectedProduct = products.filter(e=> e.id === Id)[0];
    this.setState(selectedProduct);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  view(row, event) {
    const selectedMotor = this.state.data.filter(e=> e.id === row.id)[0];
    this.setState({show: true, show_content: "", selectedMotor: selectedMotor});
  }

  delete(Ids) {    
    deleteItem(`/api/motors?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);      
      this.getMotors();
    });
  }

  componentDidMount() {
    this.getMotors();
  }
  
  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.id){
      const data = {
        "lot_ref_no_of_motor": this.state.lot_ref_no_of_motor,
        "motor_serial_no": this.state.motor_serial_no
      };
      updateData(`/api/motor/${this.state.id}`, data)
      .then((response) => {
        toast.success(response.message);        
        this.setState({show: false});        
        this.getMotors();
      });
    }else{      
      var formData = new FormData();
      var file = document.getElementById("motor-sheet").files[0];
      if(file){
        var ORfileds = document.getElementById("motor-fields");
        ORfileds.disabled=true;
        formData.append('File', file);
        postFile('/api/motor', formData)
        .then((response) => {
          toast.success(response.message);
          ORfileds.disabled=false;        
          document.getElementById("motor-form").reset();
          this.setState({show: false, show_content:""});          
          this.getMotors();
        });
      }else{
        toast.warning("Select the file first!");
      }         
    }    
  } 

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;     
      return(
        <>
          {!this.state.show?(
            <>              
              <div className="d-flex">         
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Motor
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>               
              <MotorList data={data} edit={this.edit} delete={this.delete} view={this.view} />
            </>
          ):(
            <>
              {this.state.show_content==="upload"?(
                <>
                  <div className="card motor-form p-3 bg-white shadow-sm rounded w-50 bmotor-0">
                    <h4 className="mb-3 fw-bold"> Upload Motor Data </h4>
                    <form id="motor-form" onSubmit={this.handleSubmit}>
                      <fieldset id="motor-fields" className="my-0 mx-auto"> 
                        <div className="mb-3">
                          <p className="text-secondary fw-light">Upload your excel sheet here.</p>  
                          <label htmlFor="title">Motor data file</label>
                          <input type="file" id="motor-sheet" className="form-control" onChange={this.handleFileChange} required /> 
                        </div>
                        <div className="my-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <>
                   <div className="vendor-form h-100 w-50">
                    <div className="p-4 bg-white shadow-sm rounded border-0">
                      <h4 className="mb-3 fw-bold"> Motor details </h4>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="motor-update-from" className="my-0 mx-auto">
                          <div className="row">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="org_name">Lot Reference No.</label>
                              <input type="text" name="lot_ref_no_of_motor" className="form-control shadow-none" maxLength="50" value={this.state.lot_ref_no_of_motor} onChange={this.handleInputChange} required />
                            </div>  
                             <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="org_name">Motor Serial No.</label>
                              <input type="text" name="motor_serial_no" className="form-control shadow-none" maxLength="50" value={this.state.motor_serial_no} onChange={this.handleInputChange} required />
                            </div>                                                                                                    
                          </div>                        
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const MotorList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const lotRefMatches = item.lot_ref_no_of_motor && item.lot_ref_no_of_motor.toLowerCase().includes(filterText.toLowerCase());
    const motorSNoMatches = item.motor_serial_no && item.motor_serial_no.toLowerCase().includes(filterText.toLowerCase());    

    return lotRefMatches || motorSNoMatches
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Motors_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">        
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by project's code, name ..." 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light bmotor" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
      {
          name: 'Lot Ref NO.',
          selector: row => row.lot_ref_no,
          sortable: true,
      },
      {
          name: 'Motor Serial No.',
          selector: row => row.motor_serial_no,
          sortable: true,
      },
      {
          name: 'Action',
          selector: row => row.edit
      }      
  ]; 

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,
      lot_ref_no: item.lot_ref_no_of_motor,
      motor_serial_no: item.motor_serial_no,      
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" onClick={()=> props.edit(item.id)} />
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Motors</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.lot_ref_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"             
            pointerOnHover
            className=" bmotor z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}              
          />
        </div>
      </div>
    </>
  );  
}


export { Motors };
