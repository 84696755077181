import { Component} from 'react';
import { Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

class Dashboard extends Component {
  render() {
    const isLoggedIn = sessionStorage.getItem("userToken");
    if (isLoggedIn) {
      return (
        <Container fluid className="bg-white h-100">
          <h5 className="text-secondary text-center py-5"> Welcome to Macsoft CRI SOLAR's Order Management System. </h5>          
          <h3 className="text-secondary text-center py-5"> Dashboard Comming Soon ... </h3>          
        </Container>
      );
    } else {
      return (
        <Navigate to="login" />
      );
    }
  }
}

export { Dashboard };
