import { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpFromWaterPump,
  faUser,
  faUsers,
  faWarehouse,
  faLayerGroup,
  faUserGroup,
  faCube,
  faCubes,
  faDiagramProject,
  faBars,
  faScrewdriverWrench,
  faCheckDouble,
  faGauge,
  faSolarPanel,
  faToggleOff,
  faList,
  faClipboardQuestion,
  faHardDrive,
  faFileArrowUp,
  faHeadphonesSimple
} from '@fortawesome/free-solid-svg-icons';
import { faFileLines as farFileLines, faStar, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { Dropdown, DropdownButton, SplitButton, ButtonGroup } from 'react-bootstrap';


function TopBar(props) {
  const { signOut } = useContext(AuthContext);
  let loginUser = sessionStorage.getItem("userName");
  const navbarClass = props.sidebarExpand?"navbar fixed-top bg-white shadow-sm text-dark nav-exp":"navbar fixed-top navbar-expand-md bg-white text-dark shadow-sm";
  return(
    <>
      <div className="d-none d-sm-block">
        <nav className={navbarClass}>
          <div className="container-fluid">          
            <button className="btn btn-light mx-2" onClick={props.collapseSideNav} >
              <FontAwesomeIcon icon={faBars} color="black" />
            </button>          
            {/* <Link className="navbar-brand" to="#">Navbar</Link> */}          
            <div className="d-flex justify-content-between align-items-center">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {loginUser} <FontAwesomeIcon icon={faUser} />
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end position-absolute">                    
                    <li><button className="dropdown-item dropdown_item-3" onClick={signOut}>Logout</button></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>        
        </nav>
      </div>

      {/* Mobile Navbar */}
      <div className="d-block d-sm-none">
        <nav className={navbarClass}>
          <div className="container-fluid d-flex justify-content-center">            
            <button className="btn btn-light ms-3 me-2" onClick={props.collapseSideNav} >
              <FontAwesomeIcon icon={faBars} color="black" />
            </button>
            <div className={`ms-auto d-flex align-items-center ${props.sidebarExpand?"d-none":""}`}>
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faUser} />
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end position-absolute">
                    <li><Link className="dropdown-item dropdown_item-1" to="#">Action</Link></li>
                    <li><Link className="dropdown-item dropdown_item-2" to="#">Another action</Link></li>
                    <li><button className="dropdown-item dropdown_item-3" onClick={signOut}>Logout</button></li>
                  </ul>
                </li>
              </ul>
            </div>            
          </div>
        </nav>
      </div>
    </>
  );
}

const SideNav = (props) => {
  return (
    <div>
        <aside id="sidenav" className={props.sidebarExpand?"sidenav-expand shadow-sm bg-dark bg-gradient":"sidenav-collapse shadow-sm bg-dark bg-gradient"}>
            <div className="d-flex align-items-center p-2 branding-area">
              <img className="img img-fluid" src="logo.jpg" alt="logo" />
            </div>
            <div id="LeftScrollableMenus">
              <div className="menu-container">
                <ul id="sidenav-links">
                  <li className="sidenav-item p-3">
                    <NavLink to="/" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faGauge} />
                      <span className="nav-link-text">Dashboard </span>
                    </NavLink>
                  </li>                  
                  <li className="sidenav-item p-3">
                    <NavLink to="/projects" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faDiagramProject} />
                      <span className="nav-link-text">Projects </span>
                    </NavLink>
                  </li>                 
                  <li className="sidenav-item p-3">
                    <NavLink to="/consumer-list" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faList} />
                      <span className="nav-link-text">Consumer List </span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/site-survey" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faClipboardQuestion} />
                      <span className="nav-link-text">Site Survey </span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/invoice-upload" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faFileArrowUp} />
                      <span className="nav-link-text">Invoice Upload </span>
                    </NavLink>
                  </li>                  
                  <li className="sidenav-item p-3">
                    <NavLink to="/order-status-update" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faCheckDouble} />
                      <span className="nav-link-text">Status Update </span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/order-attachments" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faFolderOpen} />
                      <span className="nav-link-text">Order Attachments </span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/asset-mapping" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faLayerGroup} />
                      <span className="nav-link-text">Asset Mapping </span>
                    </NavLink>
                  </li>                                          
                  <li className="sidenav-item p-3">
                    <NavLink to="/products" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faCubes} />
                      <span className="nav-link-text">Products </span>
                    </NavLink>
                  </li>                  
                  <li className="sidenav-item p-3">
                    <NavLink to="/bos" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faCube} />
                      <span className="nav-link-text">Bill Of Supply </span>
                    </NavLink>
                  </li>                  
                  <li className="sidenav-item p-3">
                    <NavLink to="/pumps" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faArrowUpFromWaterPump} />
                      <span className="nav-link-text">Pumps </span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/motors" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faHardDrive} />
                      <span className="nav-link-text">Motors </span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/pump-controllers" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faToggleOff} />
                      <span className="nav-link-text">Controllers </span>
                    </NavLink>
                  </li>                      
                  <li className="sidenav-item p-3">
                    <NavLink to="/solar-pv" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faSolarPanel} />
                      <span className="nav-link-text">Solar Panels </span>
                    </NavLink>
                  </li>                 
                  <li className="sidenav-item p-3">
                    <NavLink to="/stocks" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faLayerGroup} />
                      <span className="nav-link-text">Stocks </span>
                    </NavLink>
                  </li>                  
                  <li className="sidenav-item p-3">
                    <NavLink to="/warehouses" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faWarehouse} />
                      <span className="nav-link-text">Warehouses </span>
                    </NavLink>
                  </li> 
                  <li className="sidenav-item p-3">
                    <NavLink to="/vendors" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faUserGroup} />
                      <span className="nav-link-text">Vendors </span>
                    </NavLink>
                  </li>                 
                  <li className="sidenav-item p-3">
                    <NavLink to="/installer-companies" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faScrewdriverWrench} />
                      <span className="nav-link-text">Installers</span>
                    </NavLink>
                  </li>
                  <li className="sidenav-item p-3">
                    <NavLink to="/service-center" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faHeadphonesSimple} />
                      <span className="nav-link-text">Service Center</span>
                    </NavLink>
                  </li>                  
                  <li className="sidenav-item p-3">
                    <NavLink to="/users" className="d-flex align-items-center sidenav-link">
                      <FontAwesomeIcon icon={faUsers} />
                      <span className="nav-link-text">Users </span>
                    </NavLink>
                  </li>                   
                </ul>
              </div>
            </div>
        </aside>
    </div>
  );
}

const OffCanvas = (props)=> {

  async function scrollChatPage(){
    const El = await document.getElementById('chatArea');
    if(El) {
      const scrollToBottom = (node) => {
      	node.scrollTop = node.scrollHeight;
      }
      scrollToBottom(El);
    }
  }

  useEffect(()=>{
    scrollChatPage();
  },[])

  return(
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header border-bottom">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">{props.header}</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div id="chatArea" className="offcanvas-body mb-1">
        {props.body}
      </div>
      <div className="sticky-bottom">
        {props.footer}
      </div>
    </div>
  );
}

export { TopBar, SideNav, OffCanvas };
