import { Component, useEffect, useState, useCallback, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil, faPlus, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';


class ServiceCenters extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      activeKey : "sc-list",
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);    
    this.delete = this.delete.bind(this);
    this.setActiveKey = this.setActiveKey.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getServiceCenters(){
    try {
      const response = await fetch('/api/service-centers');
      const json = await response.json();
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  setActiveKey(Key){
    this.setState({ activeKey: Key, show: false });
  }

  add() {
    this.setState({
      id: "",
      org_name: "",
      org_code: "",
      address: "",
      email: "",
      phone: "",
      is_active: 1,
      show: true,
      show_content: "form"
    });
  }  

  edit(Id) {    
    const serviceCenters = this.state.data;
    const selectedServiceCenter = serviceCenters.filter(e=> e.id === Id)[0];
    this.setState(selectedServiceCenter);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  delete(Ids) {        
    deleteItem(`/api/service-centers?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getServiceCenters();
    });
  }

  componentDidMount() {
    this.getServiceCenters();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/service-center/'+data.id, data)
        .then((response) => {
          toast.success(response.message);
          this.getServiceCenters();
      });
    }else{
      postData('/api/service-center', data)
        .then((response) => {
          toast.success(response.message);
          this.getServiceCenters();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {      
      return(
        <>
          {!this.state.show?(
            <>
              <Tabs activeKey={this.state.activeKey} onSelect={(k) => this.setActiveKey(k)} id="user-tabs" className="bg-light" fill>  
                <Tab eventKey="sc-list" tabClassName="rounded-0 fw-semibold" title="Service Centers">
                  <button className="btn btn-sm btn-primary fw-bold mx-2 mt-2" onClick={this.add}>
                    <FontAwesomeIcon icon={faPlus} /> Add Service Center
                  </button>                      
                  <ServiceCenterList data={this.state.data} view={this.view} edit={this.edit} delete={this.delete} />
                </Tab>
                <Tab eventKey="district-sc" tabClassName="rounded-0 fw-semibold" title="District Wise Service Centers">
                  <DistrictSCList serviceCenters={this.state.data} />
                </Tab>
              </Tabs>                                      
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <div className="service-center-form h-100 w-50">
                  <div className="p-4 bg-white shadow-sm rounded border-0">
                    <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Service Center":"New Service Center"} </h4>
                    <form onSubmit={this.handleSubmit}>
                      <fieldset id="service-center-from" className="my-0 mx-auto">
                        <div className="row">
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="org_name">Service Center Name</label>
                            <input type="text" name="org_name" className="form-control shadow-none" maxLength="100" value={this.state.org_name} onChange={this.handleInputChange} required />
                          </div>                                                                        
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="org_code">Service Center Code</label>
                            <input type="text" name="org_code" className="form-control shadow-none" maxLength="50" value={this.state.org_code} onChange={this.handleInputChange} required />
                          </div>                          
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="address">Address</label>
                            <textarea name="address" className="form-control shadow-none" maxLength="255" rows={3} value={this.state.address} onChange={this.handleInputChange} />
                          </div>                                                                                                 
                           <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="email">Contact Email</label>
                            <input type="email" name="email" className="form-control shadow-none" maxLength="255" value={this.state.email} onChange={this.handleInputChange} />
                          </div>  
                          <div className="col-xs-12 col-md-8 mb-3">
                            <label htmlFor="phone">Contact Phone</label>
                            <input type="text" name="phone" className="form-control shadow-none" maxLength="20" value={this.state.phone} onChange={this.handleInputChange} />
                          </div>                                                                                                 
                          <div className="col-xs-12 col-md-4 mb-3">
                            <label htmlFor="title">Status</label>
                            <select name="is_active" className="form-select shadow-none" value={this.state.is_active} onChange={this.handleInputChange} required>
                              <option value="0"> Inactive </option>
                              <option value="1"> Active </option>
                            </select>
                          </div>
                        </div>                        
                        <div className="pt-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              ):(
                <>
                </>                
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const ServiceCenterList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const codeMatches = item.org_code && item.org_code.toLowerCase().includes(filterText.toLowerCase());    
    const nameMatches = item.org_name && item.org_name.toLowerCase().includes(filterText.toLowerCase());    
    return nameMatches || codeMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_ServiceCenters_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }    

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by code, name .," 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
      {
          name: 'Code',
          selector: row => row.org_code,
          sortable: true,
      },
      {
          name: 'Name',
          selector: row => row.org_name,
          sortable: true,
      },
      {
          name: 'Email',
          selector: row => row.email,          
      },
      {
          name: 'Phone',
          selector: row => row.phone,
      },      
      {
          name: 'Status',
          selector: row => row.status,
      },
      {
          name: 'Action',
          selector: row => row.edit,
      }
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,
      org_code: item.org_code,
      org_name: item.org_name,
      email: item.email,
      phone: item.phone,     
      status: <span className={`${item.is_active?"fw-semibold":"fw-bold text-danger"}`}>{item.is_active?"Active":"Inactive"}</span>,
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={()=> props.edit(item.id)} />
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Service Centers</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.org_name)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]); 
  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="350px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"                        
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}                         
          />
        </div>
      </div>
    </>
  );
}

const DistrictSCList = (props)=> {
  const [districtSC, setDistrictSC] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  async function getDistrictServiceCenters() {
    try{
      const response = await fetch("/api/districts-service-centers");
      const json = await response.json();
      setDistrictSC(json.length?json:[]);
    }catch(e){
      toast.error(e);
    }finally{
      setLoading(false);
    }
  }

  function edit(Id){    
    const selectedItem = districtSC.filter(e=> e.district_code===Id)[0];
    if(selectedItem){
      setSelectedDistrict(selectedItem);
      setShowForm(true);
    }else{
      toast.error("Something went wrong!");
    }
  }

  function handleInputChange(event){
    const name = event.target.name;
    const value = event.target.value;
    setSelectedDistrict((prevValues)=> ({
      ...prevValues,
      [name] : value
    }));    
  }

  function handleSubmit(event){
    event.preventDefault();  
    if(selectedDistrict.district_code){
      updateData('/api/district-service-center/'+selectedDistrict.district_code, selectedDistrict)
        .then((response) => {
          toast.success(response.message);
          setShowForm(false);
          getDistrictServiceCenters();
      }).catch(e=> toast.error(e));
    }else{
      toast.error("Something went wrong! Try again.")
      setShowForm(false);
    }
  }
  
  const filteredItems = districtSC.filter(item => {
    const districtMatches = item.district_name && item.district_name.toLowerCase().includes(filterText.toLowerCase());
    const stateMatches = item.state_name && item.state_name.toLowerCase().includes(filterText.toLowerCase());
    const orgCodeMatches = item.org_code && item.org_code.toLowerCase().includes(filterText.toLowerCase());
    const orgNameMatches = item.org_name && item.org_name.toLowerCase().includes(filterText.toLowerCase());
    return districtMatches || stateMatches || orgCodeMatches || orgNameMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_ServiceCenters_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }    

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by district, state, service center .," 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
      {
          name: 'district',
          selector: row => row.district,          
      },
      {
          name: 'state',
          selector: row => row.state,
      },      
      {
          name: 'Service Center Code',
          selector: row => row.org_code,
          sortable: true,
      },
      {
          name: 'Service Center Name',
          selector: row => row.org_name,
          sortable: true,
      },      
      {
          name: 'Action',
          selector: row => row.edit,
      }
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.district_code,
      district: item.district_name,
      state: item.state_name,     
      org_code: item.org_code,
      org_name: item.org_name,      
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={()=> edit(item.district_code)} />
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Districts vs Service Centers</h4>
      </>
    );
  }

  useEffect(()=>{
    getDistrictServiceCenters();
  },[])
  
  return(
    <>
      {showForm?(
        <Row xs={12}>
          <Col xs={12} md={6}>
            <div className="district-sc-form p-3 mt-1 bg-white shadow-sm rounded border-0 float-end w-100">
              <h5 className="mb-3 px-3 fw-bold text-secondary"> Edit District Service Center </h5>
              <form onSubmit={handleSubmit}>
                <fieldset id="district-sc-from" className="my-0 mx-auto px-3">
                  <div className="row">
                    <div className="col-xs-12 col-md-12 mb-3">
                      <label htmlFor="state_name">State Name</label>
                      <input type="text" name="state_name" className="form-control shadow-none bg-light" value={selectedDistrict.state_name} readOnly />
                    </div>
                    <div className="col-xs-12 col-md-12 mb-3">
                      <label htmlFor="district_name">District Name</label>
                      <input type="text" name="district_name" className="form-control shadow-none bg-light" value={selectedDistrict.district_name} readOnly />
                    </div>                                             
                    <div className="col-xs-12 col-md-12 mb-3">
                      <label htmlFor="service_center_id">Organisation</label>
                      <select name="service_center_id" className="form-select shadow-none" value={selectedDistrict.service_center_id} onChange={handleInputChange} required>
                        <option value=""> -- Select Service Center -- </option>
                        {props.serviceCenters.map(s=>
                          <option key={s.id} value={s.id}>{s.org_code} - {s.org_name}</option>
                        )}     
                      </select>
                    </div>
                  </div>                        
                  <div className="mt-3">
                    <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                    <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> setShowForm(false)}>Cancel</button>
                  </div>
                </fieldset>
              </form>
            </div>
          </Col>
        </Row>
        ):(
        <div className="mb-2 block p-2">
          <div className="shadow">
            <DataTable
              title={<THeader />}                        
              columns={columns}
              data={data}
              actions={actionComponentMemo}
              customStyles={customTableStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="430px" //remove this if you dont need table scroll
              className=" border z-10"            
              clearSelectedRows={toggleCleared}                         
            />
          </div>
        </div>
      )}      
    </>
  );
}

export { ServiceCenters };